import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import './styles/HouseRent.css';

// Function to render stars based on the rating
const renderStars = (rating) => {
  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const emptyStars = totalStars - filledStars;

  return (
    <span className="rating-stars">
      {[...Array(filledStars)].map((_, i) => (
        <span key={`filled-${i}`}>&#9733;</span>
      ))}
      {[...Array(emptyStars)].map((_, i) => (
        <span key={`empty-${i}`}>&#9734;</span>
      ))}
    </span>
  );
};

const HouseRent = () => {
  useEffect(() => {
    document.title = 'Nzubo | Property for Rent';
  }, []);

  const [houses, setHouses] = useState([]);
  const [error, setError] = useState(null);
  const [userLocation, setUserLocation] = useState({ lat: null, lon: null });

  // Search filter states
  const [locationFilter, setLocationFilter] = useState('');
  const [bedroomFilter, setBedroomFilter] = useState('');
  const [priceFilter, setPriceFilter] = useState('');
  const [exclusiveFilter, setExclusiveFilter] = useState(false);

  // Fetch user's location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    }
  }, []);

  // Calculate distance using Haversine formula
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Earth's radius in km

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  // Fetch rental houses from the API
  useEffect(() => {
    axios
      .get('https://khah-184f534d3b81.herokuapp.com/api/nyumbasp')
      .then((response) => {
        setHouses(response.data);
      })
      .catch((err) => {
        setError('Failed to fetch houses for rent. Please try again later.');
        console.error(err);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Filter and sort houses by distance (nearest first)
  const filteredHouses = houses
    .filter((house) => !house.onRent) // Only show available houses
    .filter((house) =>
      house.location.toLowerCase().includes(locationFilter.toLowerCase())
    )
    .filter((house) =>
      bedroomFilter ? house.numberOfBedrooms >= bedroomFilter : true
    )
    .filter((house) => (priceFilter ? house.price <= priceFilter : true))
    .filter((house) => (exclusiveFilter ? house.exclusive : true))
    .map((house) => ({
      ...house,
      distance: userLocation.lat && userLocation.lon ?
        calculateDistance(
          userLocation.lat,
          userLocation.lon,
          house.latitude,
          house.longitude
        ) : Infinity, // Use Infinity if location is unavailable
    }))
    .sort((a, b) => a.distance - b.distance); // Sort by distance (nearest first)

  return (
    <div className="house-rent-container">
      <div className="house-rent-heading">
        <h4>Houses for Rent</h4>
        <p>Explore houses available for rent and find the perfect place for your stay.</p>
      </div>

      {/* Search Filters */}
      <div className="search-filters">
        <input
          type="text"
          placeholder="Search by location"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Min Bedrooms"
          value={bedroomFilter}
          onChange={(e) => setBedroomFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max Price (K)"
          value={priceFilter}
          onChange={(e) => setPriceFilter(e.target.value)}
        />
      </div>
      <div className="search-filters-2">
        <label>
          <input
            type="checkbox"
            checked={exclusiveFilter}
            onChange={(e) => setExclusiveFilter(e.target.checked)}
          />
          Nzubo Only
        </label>
      </div>

      {/* Create new listing button */}
      <div className="create-new-rent-container">
        <Link to="/nyumbasp/create" className="create-new-rent-button">
          + Post House for Rent
        </Link>
      </div>

      {/* House listings */}
      {filteredHouses.length > 0 ? (
        <div className="rental-houses-list">
          {filteredHouses.map((house) => (
            <NavLink to={`/nyumbasp/${house._id}`} key={house._id} className="rent-card">
              <img
                src={house.images[0] || '/default-image.jpg'}
                alt={house.description}
                className="rent-image"
              />
              <h4>{house.location}</h4>
              <p><strong>Bedrooms:</strong> {house.numberOfBedrooms}</p>
              <p><strong>Rent:</strong> K{house.price} / month</p>
              <p><strong>Rating:</strong> {renderStars(house.rating)}</p>
              {userLocation.lat && house.distance !== Infinity && (
                <p><strong>Distance:</strong> {house.distance.toFixed(2)} km</p>
              )}
            </NavLink>
          ))}
        </div>
      ) : (
        <p className="no-rent-heading">
          No houses available matching your criteria. Please try adjusting the filters.
        </p>
      )}
    </div>
  );
};

export default HouseRent;