import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './styles/Home.css';

function Home() {
  const [formData, setFormData] = useState({ location: '' });
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const searchResultsRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Nzubo | Find your next home';
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setResult([]);
    try {
      const response = await axios.post('https://khah-184f534d3b81.herokuapp.com/api/aisearchestate', formData);
      const resultsArray = response.data.result.split('\n').filter((line) => line.trim() !== '');
      setResult(resultsArray);
    } catch (err) {
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
      searchResultsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="home-container">
    <header className="home-header">
      <h1>Find Your Next Home</h1>
      <h2>With Simplicity and Safety</h2>
    </header>
      <div className="home-wrapper">

        <main className="home-main">
          <div className='search-block'>
            <form className="search-form" onSubmit={handleSubmit}>
              <div className="input-group">
                <FontAwesomeIcon icon={faMap} className="input-icon" />
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  placeholder="Enter location..."
                  required
                />
              </div>
              <button type="submit" className="btn search-btn">
                {loading ? 'Searching...' : 'Search'}
              </button>
            </form>
          </div>

          {error && <p className="error-message">{error}</p>}

          <div ref={searchResultsRef} className="search-results">
            {result.length > 0 && (
              <>
                <h2>Search Results</h2>
                <ul>
                  {result.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </>
            )}
          </div>

          <div className="app-links">
            <a href="https://apps.apple.com/zm/app/my-nzubo/id6740543244" className="btn ios-btn">
              <FaApple style={{ marginRight: '4px' }} /> iOS
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.nzuboai" className="btn android-btn">
              <FaGooglePlay style={{ marginRight: '4px' }} />Android
            </a>

          </div>

          {/* New Property Page Link */}
          <div className="property-link">
            <a href="/nyumba" className="btn property-btn">
              View All Property
            </a>
          </div>
        </main>
      </div>

      <button className="floating-button" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
    </div>
  );
}

export default Home;
