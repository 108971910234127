import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, Link } from 'react-router-dom';
import './styles/HouseSale.css';

// Function to render stars based on the rating
const renderStars = (rating) => {
  const totalStars = 5;
  const filledStars = Math.floor(rating);
  const emptyStars = totalStars - filledStars;

  return (
    <>
      {[...Array(filledStars)].map((_, i) => (
        <span key={`filled-${i}`}>&#9733;</span> // Filled star
      ))}
      {[...Array(emptyStars)].map((_, i) => (
        <span key={`empty-${i}`}>&#9734;</span> // Empty star
      ))}
    </>
  );
};

const HouseSale = () => {
  useEffect(() => {
    document.title = 'Nzubo | Property for Sale';
  }, []);

  const [homes, setHomes] = useState([]);
  const [error, setError] = useState(null);
  const [userLocation, setUserLocation] = useState({ lat: null, lon: null });

  // Search filter states
  const [locationFilter, setLocationFilter] = useState('');
  const [bedroomFilter, setBedroomFilter] = useState('');
  const [priceFilter, setPriceFilter] = useState('');
  const [exclusiveFilter, setExclusiveFilter] = useState(false);

  // Fetch user's location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    }
  }, []);

  // Calculate distance using Haversine formula
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Earth's radius in km

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };

  // Fetch private homes from the API
  useEffect(() => {
    axios
      .get('https://khah-184f534d3b81.herokuapp.com/api/nyumbasales')
      .then((response) => {
        setHomes(response.data);
      })
      .catch((err) => {
        setError('Failed to fetch homes. Please try again later, or check your network connectivity and refresh.');
        console.error(err);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Filter and sort homes by distance (nearest first)
  const filteredHomes = homes
    .filter((home) => !home.sold) // Only show homes that are not sold
    .filter((home) =>
      home.location.toLowerCase().includes(locationFilter.toLowerCase())
    )
    .filter((home) =>
      bedroomFilter ? home.numberOfBedrooms >= bedroomFilter : true
    )
    .filter((home) => (priceFilter ? home.price <= priceFilter : true))
    .filter((home) => (exclusiveFilter ? home.exclusive : true)) // Apply exclusivity filter
    .map((home) => ({
      ...home,
      distance: userLocation.lat && userLocation.lon ?
        calculateDistance(
          userLocation.lat,
          userLocation.lon,
          home.latitude,
          home.longitude
        ) : Infinity, // Use Infinity if location is unavailable
    }))
    .sort((a, b) => a.distance - b.distance); // Sort by distance (nearest first)

  return (
    <div className="private-bnb-container">
      <div className="private-bnb-container-heading">
        <h3>Homes for Sale</h3>
        <p>Browse through locally listed properties and find your future home.</p>
      </div>

      {/* Search Filters */}
      <div className="search-filters">
        <input
          type="text"
          placeholder="Search by location"
          value={locationFilter}
          onChange={(e) => setLocationFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Min Bedrooms"
          value={bedroomFilter}
          onChange={(e) => setBedroomFilter(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max Price"
          value={priceFilter}
          onChange={(e) => setPriceFilter(e.target.value)}
        />
      </div>
      <div className="search-filters-2">
        <label>
          <input
            type="checkbox"
            checked={exclusiveFilter}
            onChange={(e) => setExclusiveFilter(e.target.checked)}
          />
          Nzubo Only
        </label>
      </div>

      {/* Button to create a new private home */}
      <div className="create-new-home-container">
        <Link to="/nyumbasale/create" className="create-new-home-button">
          + Post House for Sale
        </Link>
      </div>

      {/* Display filtered and sorted homes */}
      {filteredHomes.length > 0 ? (
        <div className="private-stays-list">
          {filteredHomes.map((home) => (
            <NavLink to={`/nyumbasales/${home._id}`} key={home._id} className="stay-card">
              <img
                src={home.images[0] || '/default-image.jpg'}
                alt={home.description}
                className="stay-image"
              />
              <h4>{home.location}</h4>
              <p><strong>Bedrooms:</strong> {home.numberOfBedrooms}</p>
              <p><strong>Price:</strong> ${home.price}</p>
              <p><strong>Rating:</strong> {renderStars(home.rating)}</p>
              {userLocation.lat && home.distance !== Infinity && (
                <p><strong>Distance:</strong> {home.distance.toFixed(2)} km</p>
              )}
            </NavLink>
          ))}
        </div>
      ) : (
        <p className="no-bnb-heading">
          No suitable properties match your search criteria. Please try adjusting the filters.
        </p>
      )}
    </div>
  );
};

export default HouseSale;